var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "toolbar p-3" },
    [
      _c(
        "div",
        { staticClass: "btn-group mr-2" },
        [
          _c(
            "CButton",
            {
              directives: [
                {
                  name: "c-tooltip",
                  rawName: "v-c-tooltip",
                  value: { content: `Back to ${_vm.folder.capitalize()}` },
                  expression: "{ content: `Back to ${folder.capitalize()}`}",
                },
              ],
              attrs: { color: "dark", to: { name: _vm.folder.capitalize() } },
            },
            [
              _c("CIcon", { attrs: { name: "cil-arrow-left" } }),
              _c("span", { staticClass: "ml-1 small" }, [
                _vm._v("Back to " + _vm._s(_vm.folder.capitalize())),
              ]),
            ],
            1
          ),
          _c(
            "CButton",
            {
              directives: [
                {
                  name: "c-tooltip",
                  rawName: "v-c-tooltip",
                  value: {
                    content: _vm.email.is_starred ? "Starred" : "Not starred",
                  },
                  expression:
                    "{\n        content: email.is_starred ? 'Starred' : 'Not starred',\n      }",
                },
              ],
              attrs: { color: "light" },
              on: {
                click: function ($event) {
                  return _vm.toggleStarred()
                },
              },
            },
            [
              _c("CIcon", {
                class: { "text-warning": _vm.email.is_starred },
                attrs: { name: "cil-star" },
              }),
            ],
            1
          ),
          _c(
            "CButton",
            {
              directives: [
                {
                  name: "c-tooltip",
                  rawName: "v-c-tooltip",
                  value: {
                    content: "Delete",
                  },
                  expression: "{\n        content: 'Delete',\n      }",
                },
              ],
              attrs: { color: "light" },
              on: { click: _vm.moveToTrash },
            },
            [_c("CIcon", { attrs: { name: "cil-trash" } })],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btn-group" },
        [
          _c("LabelDropdown", {
            attrs: { labels: _vm.email.labels },
            on: {
              "update:labels": function ($event) {
                return _vm.$set(_vm.email, "labels", $event)
              },
              change: _vm.changeLabel,
            },
          }),
          _c(
            "CButton",
            {
              attrs: { color: "light" },
              on: { click: _vm.showLabelActivityLogs },
            },
            [
              _c("CIcon", { attrs: { name: "cil-fingerprint" } }),
              _c("span", { staticClass: "ml-1 small" }, [
                _vm._v("Label History"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("LabelActivityLogs", { attrs: { messageID: _vm.messageID } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }