var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.email
    ? _c(
        "div",
        [
          _c(
            "MailToolbar",
            _vm._b({}, "MailToolbar", { email: _vm.email }, false)
          ),
          _c("div", { staticClass: "c-message p-3 flex-column" }, [
            _c(
              "div",
              { staticClass: "c-message-details" },
              [
                _c("div", { staticClass: "c-message-headers" }, [
                  _c(
                    "div",
                    { staticClass: "c-message-headers-subject mb-3" },
                    [
                      _c("h3", { staticClass: "msg-subject" }, [
                        _vm._v(_vm._s(_vm.email.subject)),
                      ]),
                      _vm.email.is_my_task
                        ? _c(
                            "CBadge",
                            { staticClass: "ml-3 badge-task" },
                            [
                              _c("CIcon", { attrs: { name: "cil-bolt" } }),
                              _vm._v(" Your Task"),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.email.is_outgoing
                        ? _c(
                            "CBadge",
                            { staticClass: "ml-3", attrs: { color: "light" } },
                            [
                              _c(
                                "span",
                                { staticClass: "text-info" },
                                [
                                  _c("CIcon", { attrs: { name: "cil-send" } }),
                                  _vm._v(" Outgoing Email"),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("CImg", {
                        staticClass: "c-avatar-img mr-3",
                        staticStyle: { width: "40px", height: "40px" },
                        attrs: {
                          src:
                            _vm.email.sender?.image_icon ||
                            _vm.email.sender?.gravatar,
                          placeholderColor: "lightgray",
                        },
                      }),
                      _c("div", [
                        _c("div", { staticClass: "c-message-headers-from" }, [
                          _c("div", [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.email.sender?.first_name)),
                            ]),
                            _c("span", { staticClass: "text-muted" }, [
                              _vm._v("<" + _vm._s(_vm.email.from) + ">"),
                            ]),
                          ]),
                          _c(
                            "div",
                            [
                              _c("CBadge", { staticClass: "badge-msg-from" }, [
                                _vm._v("TO"),
                              ]),
                              _c("span", { staticClass: "ml-1 text-muted" }, [
                                _vm._v(
                                  "<" +
                                    _vm._s(_vm.email.reply_to?.toString()) +
                                    ">"
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]),
                        _vm.email.cc && _vm.email.cc.length > 0
                          ? _c(
                              "div",
                              { staticClass: "c-message-headers-from" },
                              [
                                _c(
                                  "CBadge",
                                  { staticClass: "badge-msg-from" },
                                  [_vm._v("CC")]
                                ),
                                _c("span", { staticClass: "text-muted ml-1" }, [
                                  _vm._v(_vm._s(_vm.email.cc.join(", "))),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.email.restaurant
                          ? _c(
                              "div",
                              { staticClass: "c-message-headers-from" },
                              [
                                _c("CIcon", {
                                  attrs: { name: "cil-restaurant" },
                                }),
                                _c(
                                  "CLink",
                                  {
                                    staticClass: "font-weight-bold",
                                    attrs: {
                                      target: "_blank",
                                      to: {
                                        name: "View Restaurant",
                                        params: { id: _vm.email.restaurant_id },
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.email.restaurant.restaurant_name
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "c-message-headers-date" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.moment(_vm.email.created_at).calendar()) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", {
                  staticClass: "mt-3 c-message-body",
                  domProps: { innerHTML: _vm._s(_vm.email.reply) },
                }),
                _c("hr"),
                _c("div", { staticClass: "card" }, [
                  _c(
                    "ul",
                    { staticClass: "list-group list-group-flush" },
                    _vm._l(_vm.email.childs, function (email, index) {
                      return _c(
                        "li",
                        {
                          staticClass: "list-group-item",
                          attrs: { set: (_vm.lastMessage = email) },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "c-message-details p-0",
                              attrs: { id: email.message_id },
                            },
                            [
                              _c("div", { staticClass: "c-message-headers" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c("CImg", {
                                      staticClass: "c-avatar-img mr-3",
                                      staticStyle: {
                                        width: "40px",
                                        height: "40px",
                                      },
                                      attrs: {
                                        src:
                                          email.sender?.image_icon ||
                                          email.sender?.gravatar,
                                        placeholderColor: "lightgray",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "c-message-headers-from" },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(email.sender?.first_name)
                                              ),
                                            ]),
                                            _c(
                                              "span",
                                              { staticClass: "text-muted" },
                                              [
                                                _vm._v(
                                                  "<" + _vm._s(email.from) + ">"
                                                ),
                                              ]
                                            ),
                                            email.is_outgoing
                                              ? _c(
                                                  "CBadge",
                                                  {
                                                    staticClass: "ml-3",
                                                    attrs: { color: "light" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-info",
                                                      },
                                                      [
                                                        _c("CIcon", {
                                                          attrs: {
                                                            name: "cil-send",
                                                          },
                                                        }),
                                                        _vm._v(
                                                          " Outgoing Email"
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "CBadge",
                                              { staticClass: "badge-msg-from" },
                                              [_vm._v("TO")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "ml-1 text-muted",
                                              },
                                              [
                                                _vm._v(
                                                  "<" +
                                                    _vm._s(
                                                      email.reply_to?.toString()
                                                    ) +
                                                    ">"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "c-message-headers-date" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm
                                            .moment(email.created_at)
                                            .calendar()
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", {
                                staticClass: "mt-3 c-message-body",
                                domProps: { innerHTML: _vm._s(email.reply) },
                              }),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _vm.showReply
                  ? [
                      _c(
                        "div",
                        { ref: "reply", staticClass: "form-group reply" },
                        [
                          _c("quill-editor", {
                            attrs: {
                              options: {
                                placeholder: "Click here to reply",
                                theme: "snow",
                              },
                            },
                            model: {
                              value: _vm.reply,
                              callback: function ($$v) {
                                _vm.reply = $$v
                              },
                              expression: "reply",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "CButton",
                            {
                              staticClass: "mr-1",
                              attrs: { color: "info" },
                              on: { click: _vm.replyEmail },
                            },
                            [
                              _c("CIcon", { attrs: { name: "cil-send" } }),
                              _vm._v(" Send"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _c(
            "CElementCover",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { opacity: 1 },
            },
            [
              _c("CSpinner", {
                attrs: { size: "5xl", grow: "", color: "primary" },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "p-3" },
        [
          _vm.loading
            ? _c("CSpinner")
            : _c(
                "div",
                [
                  _c("CIcon", { attrs: { name: "cil-arrow-left" } }),
                  _vm._v(" "),
                  _c("CLink", { attrs: { to: { name: "Inbox" } } }, [
                    _vm._v("Back to Inbox"),
                  ]),
                  _c("span", { staticClass: "mx-3" }, [_vm._v("|")]),
                  _c("CLink", { attrs: { to: { name: "Task" } } }, [
                    _vm._v("Task List"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "alert alert-danger d-flex align-items-center mt-4",
                    },
                    [
                      _c("CIcon", {
                        staticClass: "mr-1",
                        attrs: { name: "cil-warning", size: "lg" },
                      }),
                      _c("strong", { staticClass: "mx-2" }, [
                        _vm._v("Warning!"),
                      ]),
                      _vm._v(
                        " All messages related to this thread have been deleted. "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }